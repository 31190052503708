import { render, staticRenderFns } from "./xuefen.vue?vue&type=template&id=9ce2b880&scoped=true"
import script from "./xuefen.vue?vue&type=script&lang=js"
export * from "./xuefen.vue?vue&type=script&lang=js"
import style0 from "./xuefen.vue?vue&type=style&index=0&id=9ce2b880&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ce2b880",
  null
  
)

export default component.exports