<!--  -->
<template>
  <div class='xuefen-view round bj-col-white'>
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="专业科目" name="zy" v-if="userInfo.kaohe == 3">
        <zy-view v-if="activeName == 'zy'" ref="zy"></zy-view>
      </el-tab-pane>
      <el-tab-pane label="公需科目" name="gx" v-if="userInfo.finish_gx == '1'">
        <gx-view v-if="activeName == 'gx'" ref="gx"></gx-view>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ZyView from './xuefen/zy.vue'
import GxView from './xuefen/gx.vue'

export default {
  components: {
    ZyView,
    GxView
  },
  data() {
    return {
      activeName: 'zy',
      userInfo: {}
    };
  },
  computed: {},
  watch: {},
  created() {
    let userInfo = localStorage.getItem("userInfo") || ''
    userInfo = userInfo ? JSON.parse(userInfo) : userInfo
    this.userInfo = userInfo
    this.activeName = this.userInfo.kaohe == 3 ? 'zy' : 'gx'
  },
  mounted() {
    
  },
  beforeDestroy () {
    
  },
  //方法集合
  methods: {

  },
}
</script>

<style lang='less' scoped>
.xuefen-view {
  padding: 30px 40px;
  /deep/ .el-tabs__item {
    font-size: 18px;
    font-weight: bold;
  }
}
</style>